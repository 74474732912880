import React, { useContext, useEffect, useState, useMemo }  from "react";
import {  
    useRefresh,   
    List,
    Datagrid,
    TextField,
    FunctionField,
    ReferenceInput,
    ReferenceField,
    TextInput,
    Filter,
    SelectInput,
    useRecordContext
} from 'react-admin';

import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    ButtonGroup,
    Backdrop,
    CircularProgress,   
    Select, 
    MenuItem, 
    FormControl, 
    InputLabel 
} from '@mui/material';
import { CustomRush } from "../utils/CustomField";

import axios from "../axiosConfig";
import useDrivePicker from "react-google-drive-picker";
import { UserContext } from "../auth/UserContext";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const Edit= (props) =>{    
    const refresh = useRefresh();
    const [isLoading, setIsLoading] = useState(false); 
    const authUser = useContext(UserContext);
    const [userTeams, setUserTeams] = useState([]);
    const [isRedo, setIsRedo] = useState(false);    
    const [reasonRedo, setReasonRedo] = useState(null); 
    const [isMsgRedo , setIsMsgRedo] = useState(false);
    const [msgRedo , setMsgRedo] = useState(null);
    const [editActive, setEditActive] = useState(false);
    const [isAssignTask, setIsAssignTask] = useState(false);
    const [assignType, setAssignType] = useState('');
    const [userAssign, setUserAssign] = useState([]);
    const [userSelected, setUserSelected] = useState(null);
    const [filter, setFilter] = useState({OR: [{ task:{job: {team: {
        OR: [
            { managers: { has: authUser.id } },
            { leaders: { path: ['edit'], array_contains: authUser.id } },
        ]
    }}}},{staffIds:{has:authUser.id}}]});

    useEffect(() => {
        const uniqueTeamIds = authUser.teams.filter(item => item.role>=0 ).map(item => item.teamId);
        setUserTeams(uniqueTeamIds);
    }, [authUser]);

    const EditRowStyle = (record, teams) => {
        let response = { backgroundColor: 'orange' }; 
        if(record.staffIds.length === 1 && record.staffIds[0] === authUser.id && record.task.rush){  return { backgroundColor: '#c9a0dc' }};
        if(record.staffIds.length > 1 && record.staffIds[1] === authUser.id && record.task.rush){  return { backgroundColor: '#c9a0dc' }};
        switch(record.status){
            case 'Completed': {response = { backgroundColor: '#6df7a4' }; break;}
            case 'CreatorApproved':{
                if(record.task.job.team.managers.includes(authUser.id)) {response = { backgroundColor: '#f5f774' }}
                break;
            }
            case 'LeaderApproved':{
                if(authUser.id ===  record.staffIds[0]) {response =  { backgroundColor: '#f5f774' } } 
                break;
            }
            case 'Submitted':{
                if(record.task.job.team.leaders.edit.includes(authUser.id)) {response = { backgroundColor: '#f5f774' }}
                break;
            }
            case 'Working':{
                if(authUser.id ===  record.staffIds[1]) {response = { backgroundColor: '#f5f774' }}
                else {response = { backgroundColor: '' }}
                break;
            }
            case 'Redo':{
                if((record.staffIds.length === 1 && authUser.id ===  record.staffIds[0])|| (record.staffIds.length === 2 && authUser.id ===  record.staffIds[1])){response = { backgroundColor: '#ffc0cb' }}
                else {response = { backgroundColor: '' }}
                break;
            }
            default:{
                if(authUser.id ===  record.staffIds[0]) {response = { backgroundColor: '#f5f774' }}
                else {response = { backgroundColor: '' }}
            }
        }
        return response;
    };
    
    const EditFilter = () => {
        const status = useMemo(() => [
            { id: 'Open', name: 'New' },
            { id: 'Working', name: 'Working' },
            { id: 'Submitted', name: 'Submitted' },
            { id: 'LeaderApproved', name: 'Leader Approved' },
            { id: 'CreatorApproved', name: 'Creator Approved' },
            { id: 'Completed', name: 'Completed' },
        ], []);    
        return (
            <Filter >
                <SelectInput label="Status" source="status" choices={status} alwaysOn onChange={filterWithStatus} />
                <ReferenceInput  source="staffId" reference="user" alwaysOn filter={{teams:{some:{teamId:{in:userTeams}, role:{gte:0}}}}} sort={{ field: 'firstName', order: 'ASC' }}>
                    <SelectInput label="User" optionText="firstName" optionValue="id" />
                </ReferenceInput>
                <TextInput label="Title" source="title"  alwaysOn/>
            </Filter>
        );
    };

    const filterWithStatus = () => {
        setFilter({
            OR: [
                { task:{job: {team: {
                    OR: [
                        { managers: { has: authUser.id } },
                        { leaders: { path: ['edit'], array_contains: authUser.id } },
                    ]
                }}}},
                {staffIds:{has:authUser.id}}
            ]
        });
    };

    const CreatorField = () => {
        return <ReferenceField source='staffIds[0]' reference="User" >
            <TextField source="firstName" />
        </ReferenceField> 
    }

    const EditorField = ({label, source}) => {
        const record = useRecordContext();
        return <ReferenceField source={record.staffIds.length>1?'staffIds[1]':'staffIds[0]'} reference="User" >
            <TextField source="firstName" />
        </ReferenceField> 
    }

    const [openPicker] = useDrivePicker();

    const handleAssignTask = async (record) => {
        setEditActive(record);
        setIsAssignTask(true);        
    };

    const changeAssignTaskType = async (e) => {
        setAssignType(e.target.value);
        const endpoint = e.target.value === 'team'? 'api/user/findLeader': 'api/user/findStaff';
        const users = await axios.get(`${process.env.REACT_APP_API_URL}${endpoint}?type=edit&team=${editActive.task.job.teamId}`);
        setUserAssign(users.data);
    }

    const handleSubmitAssignTask = async () => {
        if (!window.confirm('Are you sure you want to assign this task?')) return;    
        setIsLoading(true);
        handleCloseAssignTask();
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/edit/assignTask`, { 
                type: assignType,
                user: userSelected, 
                editId : editActive.id,
                staffIds: [authUser.id, userSelected.id]
            });            
        }
        catch(err){
            console.log(err);
        } finally { 
            refresh();
            setIsLoading(false);
        }   
    }

    const handleCloseAssignTask = () => {
        setAssignType('');
        setEditActive(null);
        setIsAssignTask(false);
        setUserSelected(null);
        setUserAssign([]);
    }   

    const handleEditUpdate = async (edit, status, isRedo) => {
        const isEditLeader = (edit.task.job.team.leaders.edit.includes(authUser.id)) ? true:false;
        const isEditManager = (edit.task.job.team.managers.includes(authUser.id)) ? true:false;
        let title = 'Approve';
        if((status === 'LeaderApproved' && isEditLeader) || status === 'Submitted') {title = 'Submit';}
        if(isEditManager && (status === 'CreatorApproved' || status === 'LeaderApproved')) {status = 'Completed';}
        if (!window.confirm('Are you sure you want to '+ title +' this Video?')) return;
        // check is submit of Redo
        if(isRedo === 'fixRedo'){status = edit.task.statusDetail[0].dataReject.oldStatus}
        setIsLoading(true);  
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/edit/${edit.id}`,  {status: status});   
            if(isRedo === true) {
                await axios.put(`${process.env.REACT_APP_API_URL}api/task/reject/${edit.task.id}`, { // update task
                    reason:reasonRedo,  
                    userReject:authUser.id,
                    oldStatus: edit.status,
                });
            }     
        }
        catch(err){
            console.log(err);
        } finally {
            refresh();
            setIsLoading(false);
        }           
    }

    const insertFrameIo = async (id,oldUrls) => {
        let frameIoUrl = prompt('Please enter the Frame IO URL:');        
        if(!frameIoUrl) return;
        const hasProtocol = frameIoUrl.startsWith('https://');
        const hasDomain = frameIoUrl.includes('f.io/');
        if (!hasDomain) {
            alert('Invalid url. Please enter a valid Frame IO URL');
            return;
        }
        if (!hasProtocol && hasDomain) {
            // Xác định xem URL có bắt đầu bằng 'f.io/' không và thêm 'https://'
            if (frameIoUrl.startsWith('f.io/')) {
                frameIoUrl = 'https://' + frameIoUrl;
            } else {
                // Nếu input bắt đầu bằng 'www.f.io/' hoặc chỉ 'f.io', thêm 'https://'
                frameIoUrl = 'https://' + frameIoUrl.slice(frameIoUrl.indexOf('f.io/'));
            }
        }
        oldUrls.push(frameIoUrl);
        try{
            setIsLoading(true);            
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/edit/${id}`,  {frameIoUrls: oldUrls});       
        }
        catch(err){
            console.log(err);
        } finally {
            refresh();
            setIsLoading(false);
        }        
    }

    const handleSelectVideo = async (record) => {
        let token = await axios.get(`${process.env.REACT_APP_API_URL}api/token/googleToken?id=${authUser.id}`);
        openPicker({
            clientId : process.env.REACT_APP_GOOGLE_CLIENT_ID,
            developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
            viewId: "DOCS_VIDEOS",
            token:  token.data,
            supportDrives: true, 
            setParentFolder : record.task.job.directory,         
            cropping: true,
            setOrigin: process.env.REACT_APP_GOOGLE_ORIGIN,
            callbackFunction: async (data) => {
                if (data.action === 'cancel') {
                    console.log('User clicked cancel/close button')
                } else if (data.action === 'picked') {
                    if(!window.confirm('Are you sure you want to select this video?')) return;
                    setIsLoading(true);
                    try{
                        await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/edit/${record.id}`,  {video: data.docs[0]});                        
                        await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/idea/publicImage`, data.docs);
                    }
                    catch(err){
                        console.log(err);
                    } finally {
                        refresh();
                        setIsLoading(false);
                    }   
                }
            }
        })
    }
    
    const ActionRenderer = ({record, teams}) => {
        const isLeader = (record.task.job.team.leaders.edit.includes(authUser.id)) ? true:false;
        const isManager = (record.task.job.team.managers.includes(authUser.id))? true:false;
        const isSubmitted = record.video? true : false;
        switch(record.status){
            case 'Completed':{
                return <span>Approved</span>
            }
            case 'CreatorApproved':{
                if(isManager){
                    return( 
                        <ButtonGroup>
                            <Button variant="outlined" color="primary" onClick={() => handleEditUpdate(record,"Completed", false)}>Approve</Button>
                            <Button variant="outlined" color="primary" onClick={() => {setIsRedo(true); setEditActive(record)}}>Redo</Button>
                            <Button variant="outlined" color="primary" onClick={() => {insertFrameIo(record.id,record.frameIoUrls)}}>Insert Frame Io</Button>
                        </ButtonGroup> )
                }
                else return <span>In Review</span>
            }
            case 'LeaderApproved':{
                if(authUser.id ===  record.staffIds[0]){    
                    return( 
                        <ButtonGroup>
                            <Button variant="outlined" color="primary" onClick={() => handleEditUpdate(record,isManager?"Completed":"CreatorApproved", false)}>Approve</Button>
                            <Button variant="outlined" color="primary" onClick={() => {setIsRedo(true); setEditActive(record)}}>Redo</Button>
                            <Button variant="outlined" color="primary" onClick={() => {insertFrameIo(record.id,record.frameIoUrls)}}>Insert Frame Io</Button>
                        </ButtonGroup> )
                }
                else return <span>In Review</span>
            }
            case 'Submitted':{
                if(isLeader){  
                    return <ButtonGroup>
                        <Button variant="outlined" color="primary" onClick={() => handleEditUpdate(record,isManager?"Completed":"LeaderApproved", false)}>Approve</Button>
                        <Button variant="outlined" color="primary" onClick={() => {setIsRedo(true);setEditActive(record)}}>Redo</Button>
                        <Button variant="outlined" color="primary" onClick={() => {insertFrameIo(record.id,record.frameIoUrls)}}>Insert Frame Io</Button>
                    </ButtonGroup>
                } 
                else return <span>In Review</span>
            }
            case 'Working':{
                if(authUser.id ===  record.staffIds[1]){                    
                    return( 
                    <ButtonGroup> 
                        <Button variant="outlined" color="primary" onClick={()=>window.open(`https://drive.google.com/drive/folders/${record.task.job.directory}`, "_blank")}>Upload</Button> 
                        <Button variant="outlined" color="primary" onClick={() =>handleSelectVideo(record)}>Select Video </Button>
                        {isSubmitted && isLeader && <Button variant="outlined" color="primary" onClick={() => {insertFrameIo(record.id,record.frameIoUrls)}}>Insert Frame Io</Button>}
                        {isSubmitted && <Button variant="outlined" color="primary" onClick={() =>handleEditUpdate(record, isLeader? "LeaderApproved": "Submitted", false)}>Submit</Button>}
                    </ButtonGroup> )
                }
                else return <span>Waiting for Submission</span>
            }
            case 'Redo':{
                if(record.staffIds.length ===1 && record.staffIds[0]===authUser.id){
                    return(
                        <ButtonGroup>            
                            <Button variant="outlined" color="info" onClick={()=>window.open(`https://drive.google.com/drive/folders/${record.task.job.directory}`, "_blank")}>Upload</Button>
                            <Button variant="outlined" color="primary" onClick={() =>handleSelectVideo(record)}>Select Video </Button>
                            {!isSubmitted &&<Button variant="outlined" color="primary" onClick={() =>handleAssignTask(record)}>Assign Task </Button>}
                            {isSubmitted && <Button variant="outlined" color="primary" onClick={() =>handleEditUpdate(record,"CreatorApproved", 'fixRedo')}>Submit</Button>}
                        </ButtonGroup>
                    )
                }
                else if(record.staffIds.length ===2 && record.staffIds[1]===authUser.id){
                    return(
                        <ButtonGroup>            
                            <Button variant="outlined" color="info" onClick={()=>window.open(`https://drive.google.com/drive/folders/${record.task.job.directory}`, "_blank")}>Upload</Button>
                            <Button variant="outlined" color="primary" onClick={() =>handleSelectVideo(record)}>Select Video </Button>
                            {isSubmitted && <Button variant="outlined" color="primary" onClick={() =>handleEditUpdate(record, isLeader? "LeaderApproved": "Submitted", 'fixRedo')}>Submit</Button>}
                        </ButtonGroup>
                    )
                }
                else return <span>Waiting for Submission</span>

            }
            default:{
                if(authUser.id ===  record.staffIds[0]){
                    return(
                        <ButtonGroup>            
                            <Button variant="outlined" color="info" onClick={()=>window.open(`https://drive.google.com/drive/folders/${record.task.job.directory}`, "_blank")}>Upload</Button>
                            <Button variant="outlined" color="primary" onClick={() =>handleSelectVideo(record)}>Select Video </Button>
                            {!isSubmitted &&<Button variant="outlined" color="primary" onClick={() =>handleAssignTask(record)}>Assign Task </Button>}
                            {isSubmitted && <Button variant="outlined" color="primary" 
                                onClick={() =>handleEditUpdate(record,isManager? "Completed": isLeader? "LeaderApproved" :"CreatorApproved", false)}
                            >Submit</Button>}
                        </ButtonGroup>
                    )
                }
                else return <span>Waiting for Submission</span>
            }
        }
    }

    const MessageField = ({data}) => { 
        if(!data[0]||!data[0].dataReject) return null;
        let strMsg = data[0].dataReject.reason;
        if(data[0].dataReject.reason.length>3){strMsg = data[0].dataReject.reason.substring(0,48)+"... ";}
        return <span title="click view more..." onClick={()=>handleMsgClick(data[0].dataReject.reason)} style={{cursor: "pointer"}}>{strMsg}</span>;     
    }
    const handleMsgClick = (data) => {
        setIsMsgRedo(true);
        setMsgRedo(data);
    }

    return (
        <List {...props} filter={filter} >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
            <EditFilter />
            <Dialog //Dialog for Assign Task
                open={isAssignTask}
                onClose={handleCloseAssignTask}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Assign Task:</DialogTitle>
                <DialogContent >
                    <FormControl fullWidth>
                        <InputLabel id="assign-type-label">Assign Type</InputLabel>
                        <Select
                            labelId="assign-type-label"
                            value={assignType}
                            onChange={changeAssignTaskType}
                        >
                            <MenuItem value=""><em>Select Assign Type</em></MenuItem>
                            <MenuItem value="team">Assign for Team</MenuItem>
                            <MenuItem value="user">Assign for User</MenuItem>
                        </Select>
                    </FormControl>
                    {userAssign.length > 0 && (
                        <FormControl fullWidth style={{ marginTop: '1rem' }}>
                            <InputLabel id="user-assign-label">{assignType==='team'? 'Select Team':'Select User'}</InputLabel>
                            <Select
                                labelId="user-assign-label"
                                value={userSelected?.id || ""}
                                onChange={(e) => setUserSelected({ id: parseInt(e.target.value) })}
                            >
                                {userAssign.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.firstName}{assignType==='team'?`'s Team`:''}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAssignTask} color="primary">Cancel</Button>
                    {userSelected && <Button onClick={handleSubmitAssignTask} color="primary">Submit</Button>}
                </DialogActions>
            </Dialog>
            <Dialog  //Dialog for Redo Edit
                open={isRedo}
                onClose={()=>setIsRedo(false)}
                fullWidth={true}
            >
                <DialogTitle>Reason for Reject: </DialogTitle>
                <DialogContent>
                    <textarea  
                        fullWidth  
                        style={{ width: '99%', minHeight: '8em' }}
                        onChange={(e) => setReasonRedo(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{handleEditUpdate(editActive, "Redo", true);  setIsRedo(false);}} color="primary">Redo </Button>
                    <Button onClick={()=>setIsRedo(false)} color="primary">Cancel </Button>
                </DialogActions>
            </Dialog>
            <Dialog  //Dialog for msg edit
                open={isMsgRedo}
                onClose={()=>{setIsMsgRedo(false); setMsgRedo(null)}}
                fullWidth={true}
            >
                <DialogTitle>Reason for Reject: </DialogTitle>
                <DialogContent>
                    <textarea  
                        fullWidth  
                        style={{ width: '99%', minHeight: '8em' }}
                        value={msgRedo}
                    />
                </DialogContent>
            </Dialog>
            <Datagrid rowStyle={record => EditRowStyle(record, userTeams) } bulkActionButtons={false}>                
                <FunctionField label="Title" render={record =>record.task.job.name} />  
                <FunctionField label="" render={record =>(
                    <a href={`/#/job/${record.task.job.id}/show`} target="_blank" rel="noreferrer">View Project</a>
                )} />
                <FunctionField label="" render={record =>(
                    <a href={`https://docs.google.com/document/d/${record.task.job.jobInfo.script}/edit`} target="_blank" rel="noreferrer">View Script</a>
                )} />
                <FunctionField label="Frame IO" render={(record) =>{
                    if(record.frameIoUrls.length===0) return <span>No Url</span>
                    return (
                        <ol>
                            {record.frameIoUrls.map((link, index)=> (<li key={index}><a target="_blank" rel="noreferrer" href={link}>{link}</a></li>))}
                        </ol>
                    ) 
                }}/>
                <FunctionField label="Video" render={(record) => {
                    if(!record.video) return <span>Not Selected</span>
                    return <PlayArrowIcon  onClick={()=>window.open(record.video.url, "_blank")} style={{ color: 'blue',cursor:'pointer'}}/>                
                }} />
                <CreatorField label="Creator" source="staffIds[0]" sortable />
                <EditorField label="Editor"  source="staffIds[1]"  />
                <TextField source="status"/>
                <FunctionField label="Message" render={(record) =>(<MessageField data={record.task.statusDetail} /> )} />
                <FunctionField label="Actions" render={(record) => (<ActionRenderer record={record} teams={userTeams}/>)}/>
                <FunctionField label="Rush" render={(record) => <CustomRush record={record} setIsLoading={setIsLoading} userTeams={userTeams} />} />
            </Datagrid>
        </List>
    )
    };

export default Edit;