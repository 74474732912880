import React, {useEffect, useContext, useState,  useMemo }  from "react";
import { useNavigate  } from 'react-router-dom';
import {     
    List,
    Datagrid,
    TextField,
    ReferenceField,   
    ReferenceInput,
    TextInput, 
    FunctionField,
    SelectInput,
    useRefresh,
    Filter,
    useRecordContext
} from 'react-admin';
import axios from "../axiosConfig";
import {StaffRowStyle } from "../utils/CustomRowStyle";
import { isWeekend, isPast, parseISO, format } from 'date-fns';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
    Button,
    Backdrop,
    CircularProgress
}from '@mui/material';

import { UserContext } from "../auth/UserContext";

const ImageRenderer = ({jobInfo}) => {
    if(!jobInfo || !jobInfo.idea || jobInfo.idea.length <1) return <img src="/images/no-photo.jpg"  height="72" alt="not found" />;
    return (
        <div>
             <a 
                href={`https://drive.google.com/file/d/${jobInfo.idea[0].id}/view`} 
                target="_blank" rel="noreferrer">
                <img src={`https://drive.google.com/thumbnail?id=${jobInfo.idea[0].id}`} alt="img from GG" height="72" loading="lazy"  />
            </a>
        </div>
    );
};

const Film= (props) =>{     
    const navigate = useNavigate();
    const authUser = useContext(UserContext);
    const [teamIds, setTeamIds] = useState([]);
    const refresh = useRefresh();
    const [isLoading, setIsLoading] = useState(false); 
    const [filter, setFilter] = useState({OR: [{ task:{job: {team: { managers: { has: authUser.id } }}}},{staffId:authUser.id}]});

    const [jobId, setJobId] = useState(null)
    const [openChangeDate, setOpenChangeDate] = useState(false);
    const [scheduleDate, setScheduleDate] = useState(null);  
    const [disableChangeDate, setDisableChangeDate] = useState(true);  

    useEffect(() => {
        const uniqueTeamIds = authUser.teams.filter(item => item.role>=0 ).map(item => item.teamId);
        setTeamIds(uniqueTeamIds);
    }, [authUser]);

    const TitleField = () => {
        const record = useRecordContext();
        return <span>{record.task.job.name}</span>;
    }

    const FilmFilter = () => {
        const status = useMemo(() => [
            { id: 'Open', name: 'New' },
            { id: 'Completed', name: 'Completed' }, 
        ], []);    
        return (
            <Filter >
                <SelectInput label="Status" source="status" choices={status} alwaysOn onChange={filterWithStatus} />
                <ReferenceInput  source="staffId" reference="user" alwaysOn filter={{teams:{some:{teamId:{in:teamIds}, role:{gte:0}}}}} sort={{ field: 'firstName', order: 'DESC' }}>
                    <SelectInput label="User" optionText="firstName" optionValue="id" />
                </ReferenceInput>
                <TextInput label="Title" source="title"  alwaysOn/>
            </Filter>
        );
    };
    const filterWithStatus = () => {
        setFilter({OR: [{ task:{job: {team: { managers: { has: authUser.id } }}}},{staffId:authUser.id}]},);
    }

    const RawFileField = ({type}) => {
        const rawFolder = type === 'video' ? 'rawVideosFolder' : 'rawImagesFolder';
        const record = useRecordContext();
        if (record.status === "Completed" || (record.info && record.info[type])) {
            return <a href={"https://drive.google.com/drive/folders/"+record.task.job.jobInfo[rawFolder]} target="_blank" rel="noreferrer">Raw {type}s</a>;
        } else if(record.staffId === authUser.id){
            return <ButtonGroup variant="contained" >
                <Button variant="contained" color="primary" onClick={()=>window.open("https://drive.google.com/drive/folders/"+record.task.job.jobInfo[rawFolder],"_blank")}>Upload</Button>
                <Button variant="contained" color="info" onClick={()=>{handleSubmitRawFile(type, record)}}>Submit</Button>
            </ButtonGroup>
        } else return <span></span>;
    };

    const handleSubmitRawFile = async (type, record) => {
        if(!window.confirm('Are you sure you have uploaded the entire file and that the process has been completed?')) return;
        setIsLoading(true);    
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/film/${record.id}`,  {type:type, data: {info: {...record.info, [type]:true}}});     
        }
        catch(err){
            console.log(err);
        } finally {
            refresh();
            setIsLoading(false);
        }

    };

    const handleSwitchDate = (job) =>{
        setScheduleDate(job.filmingDate ? format(new Date(job.filmingDate), 'yyyy-MM-dd') : '');
        setOpenChangeDate(true);
        setJobId(job.id);        
    }
    const handleChangeDate = (e)=>{
        const selectedDate = parseISO(e.target.value); // Chuyển đổi chuỗi ngày ISO thành đối tượng Date
        const today = new Date();
        if (isWeekend(selectedDate) || isPast(selectedDate, today)) {
            alert("The selected date is invalid");
            setScheduleDate(null);
            setDisableChangeDate(true)
            return; // Giữ nguyên giá trị cũ
        }
        setScheduleDate(e.target.value);
        setDisableChangeDate(false);
    }
    const changeDate = async () => {
        try{
            handleCloseDialog(); 
            setIsLoading(true);
            let localDate = parseISO(scheduleDate);
            localDate.setHours(8,0,0,0);
            let utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);     
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/film/changeDate/${jobId}`, {filmingDate: utcDate.toISOString()});   
        }
        catch(err){
            console.log(err);
        } finally {
            refresh();
            setDisableChangeDate(false);
            setIsLoading(false);
        }          
    };
    const handleCloseDialog =()=>{
        setOpenChangeDate(false); 
        setScheduleDate(null);
        setJobId(null);
    }
    const handleFilm = async (record) => {
        if(!window.confirm('Are you sure you want to mark this film as filmed?')) return;
        setIsLoading(true);    
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/film/${record.id}`,  {data:{status: 'Completed', info: {...record.info,"userSubmit":authUser.id}}});     
        }
        catch(err){
            console.log(err);
        } finally {
            refresh();
            setIsLoading(false);
            navigate('/workspace/edit');
        }
    };  

    return (
        <List {...props} filter={filter}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
            <FilmFilter />
            <Dialog
                open={openChangeDate}
                onClose={handleCloseDialog}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Change Film Date</DialogTitle>
                <DialogContent>
                    <input 
                        type="date" 
                        value ={scheduleDate || ''}
                        onChange={(e)=>{handleChangeDate(e)}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
                    <Button onClick={()=>changeDate()} color="primary" disabled={disableChangeDate} >Change Date</Button>
                </DialogActions>
            </Dialog>
            <Datagrid rowStyle={StaffRowStyle(authUser, teamIds)} bulkActionButtons={false}> 
                <FunctionField label="Image" render={(record) => (
                    <ImageRenderer jobInfo={record.task.job.jobInfo} />
                )}/>
                <TitleField label="Title" source="title"  sortable/> 
                <FunctionField label="" render={record =>(
                    <a href={`https://docs.google.com/document/d/${record.task.job.jobInfo.script}/edit`} target="_blank" rel="noreferrer">View Script</a>
                )} />   
                <FunctionField label="" render={record =>(
                    <a href={`/#/job/${record.task.job.id}/show`} target="_blank" rel="noreferrer">View Project</a>
                )} />
                <ReferenceField source="staffId" reference="User" label="Creator">
                    <TextField label="User" source="firstName" />
                </ReferenceField>              
                <TextField source="status"/>
                <FunctionField label="Film At" render={record =>{
                    const localDate = new Date(record.task.job.filmingDate);
                    return format(localDate, 'yyyy-MM-dd');
                }} />
                <RawFileField label="Raw Videos" type="video"/>                
                <RawFileField label="Raw Images" type="image"/>                
                <FunctionField label="Actions" render={(record) =>{
                    const indexInTeam = teamIds.indexOf(record.task.job.team.id);
                    const isLeader = authUser.teams[indexInTeam] && authUser.teams[indexInTeam].role > 1;
                    if (record.status === "Completed") return <span>Filmed</span>;
                    if(isLeader && record.staffId !== authUser.id){
                        return <Button variant="outlined" color="primary" onClick={()=>handleSwitchDate(record.task.job)} >Switch Date</Button>
                    }
                    return (
                        <div>
                            {isLeader && (!record.info || !record.info['video'] ) &&  <Button variant="outlined" color="primary" onClick={()=>handleSwitchDate(record.task.job)} >Switch Date</Button>}                           
                            <Button variant="outlined" color="primary" onClick={() => handleFilm(record)}>Submit</Button>                      
                        </div>
                    )
                }}/>
            </Datagrid>
        </List>
    )
    };

export default Film